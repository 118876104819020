var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-container"},[_c('div',{staticClass:"card-container"},[_c('b-card',{staticClass:"mb-0 custom-card"},[_c('b-link',{staticClass:"brand-logo",attrs:{"to":{ name: '/' }}},[_c('h2',{staticClass:"brand-text text-primary text-center"},[_vm._v("Emoty")])]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('Locale')],1),_c('validation-observer',{ref:"vendorRegisterValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t.t('registration.identification'),"label-for":"v-id"}},[_c('validation-provider',{attrs:{"name":"identification number","rules":{
                    required: _vm.required,
                    regex: /^(?:\d{9}|\d{11})$/,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-id","type":"number","placeholder":_vm.t.t('registration.identification')},on:{"change":_vm.checkForId},model:{value:(_vm.identificationCode),callback:function ($$v) {_vm.identificationCode=$$v},expression:"identificationCode"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("global.identificationCheck"))+" ")]):_vm._e()]}}])}),_c('b-form-checkbox',{staticClass:"mt-05",model:{value:(_vm.isIndividualEntrepreneur),callback:function ($$v) {_vm.isIndividualEntrepreneur=$$v},expression:"isIndividualEntrepreneur"}},[_vm._v(" "+_vm._s(_vm.t.t("registration.is-entrepreneur"))+" ")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t.t('registration.email'),"label-for":"v-email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required||email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-email","placeholder":_vm.t.t('registration.email'),"type":"email"},on:{"change":_vm.checkForEmail},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("global.emailCheck"))+" ")]):_vm._e()])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t.t('registration.mobile'),"label-for":"v-mobile"}},[_c('validation-provider',{attrs:{"name":"mobile number","rules":{
                    required: _vm.required,
                    regex: /^(?:\d{9})$/,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-mobile","type":"number","placeholder":_vm.t.t('registration.mobile')},on:{"change":_vm.checkForMobile},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("global.phoneNumberCheck"))+" ")]):_vm._e()]}}])})],1)],1),_c('div',{staticClass:"m-1"},[_c('p',{staticClass:"m-0",staticStyle:{"font-weight":"600","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.$t("global.passwordMustBe"))+": ")]),_c('p',{staticClass:"m-0",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(_vm.$t("global.passLineOne"))),_c('br'),_vm._v(" "+_vm._s(_vm.$t("global.passLineTwo"))+" ")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"account-password","label":_vm.t.t('registration.password')}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":{
                    regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                    required: _vm.required,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-password","type":_vm.passwordFieldType,"name":"password","placeholder":_vm.t.t('registration.password')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("global.passLineOne"))+" "+_vm._s(_vm.$t("global.passLineTwo"))+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12 d-flex justify-content-end"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"back","variant":"outline-secondary"},on:{"click":_vm.goToLoginPage}},[_vm._v(" "+_vm._s(_vm.t.t("global.previous"))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"type":"submit","variant":"primary","disabled":_vm.disabledRegisterButton},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.t.t("global.submit"))+" ")])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }