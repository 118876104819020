<template>
  <div class="auth-container">
    <div class="card-container">
      <!-- Register v1 -->
      <b-card class="mb-0 custom-card">
        <b-link :to="{ name: '/' }" class="brand-logo">
          <h2 class="brand-text text-primary text-center">Emoty</h2>
        </b-link>
        <div class="d-flex justify-content-end">
          <Locale />
        </div>
        <validation-observer ref="vendorRegisterValidation">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="t.t('registration.identification')"
                  label-for="v-id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="identification number"
                    :rules="{
                      required,
                      regex: /^(?:\d{9}|\d{11})$/,
                    }"
                  >
                    <b-form-input
                      id="v-id"
                      v-model="identificationCode"
                      type="number"
                      :placeholder="t.t('registration.identification')"
                      @change="checkForId"
                    />
                    <small v-if="errors[0]" class="text-danger">
                      {{ $t("global.identificationCheck") }}
                    </small>
                  </validation-provider>
                  <b-form-checkbox
                    v-model="isIndividualEntrepreneur"
                    class="mt-05"
                  >
                    {{ t.t("registration.is-entrepreneur") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <!-- email -->
              <b-col cols="12">
                <b-form-group
                  :label="t.t('registration.email')"
                  label-for="v-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required||email"
                  >
                    <b-form-input
                      id="v-email"
                      v-model="userEmail"
                      :placeholder="t.t('registration.email')"
                      type="email"
                      @change="checkForEmail"
                    />
                    <small class="text-danger">
                      <small v-if="errors[0]" class="text-danger">
                        {{ $t("global.emailCheck") }}
                      </small>
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- mobile -->
              <b-col cols="12">
                <b-form-group
                  :label="t.t('registration.mobile')"
                  label-for="v-mobile"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="mobile number"
                    :rules="{
                      required,
                      regex: /^(?:\d{9})$/,
                    }"
                  >
                    <b-form-input
                      id="v-mobile"
                      v-model="mobile"
                      type="number"
                      :placeholder="t.t('registration.mobile')"
                      @change="checkForMobile"
                    />
                    <small v-if="errors[0]" class="text-danger">
                      {{ $t("global.phoneNumberCheck") }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- password -->
              <div class="m-1">
                <p class="m-0" style="font-weight: 600; font-size: 12px">
                  {{ $t("global.passwordMustBe") }}:
                </p>
                <p class="m-0" style="font-size: 11px">
                  {{ $t("global.passLineOne") }}<br />
                  {{ $t("global.passLineTwo") }}
                </p>
              </div>
              <b-col cols="12">
                <b-form-group
                  label-for="account-password"
                  :label="t.t('registration.password')"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Password"
                    vid="Password"
                    :rules="{
                      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                      required,
                    }"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-password"
                        v-model="password"
                        :type="passwordFieldType"
                        name="password"
                        :placeholder="t.t('registration.password')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small v-if="errors[0]" class="text-danger">
                      {{ $t("global.passLineOne") }}
                      {{ $t("global.passLineTwo") }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit and reset -->
              <b-col cols="12 d-flex justify-content-end">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="back"
                  variant="outline-secondary"
                  @click="goToLoginPage"
                >
                  {{ t.t("global.previous") }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="ml-1"
                  :disabled="disabledRegisterButton"
                  @click.prevent="validationForm"
                >
                  {{ t.t("global.submit") }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BLink,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import Swal from "sweetalert2";
import { parseJwt } from "@/auth/utils";

export default {
  components: {
    BCard,
    Locale,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      privacyPopupIsOpen: false,
      identificationCode: "",
      isIndividualEntrepreneur: false,
      userEmail: "",
      mobile: "",
      password: "",
      passwordFieldType: "password",
      required,
      email,
      disabledRegisterButton: false,
      t: null,
    };
  },
  computed: {
    ...mapState("vendorRegistration", ["errors"]),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  created() {
    this.t = this.$i18n;
  },
  methods: {
    ...mapActions("vendorRegistration", [
      "vendorRegistration",
      "mobileCheck",
      "emailCheck",
      "identificationCodeCheck",
      "clearErrors",
    ]),
    async checkForId() {
      const response = await this.identificationCodeCheck(
        this.identificationCode
      );
      if (response.data.error?.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.data.error.message,
            icon: "BellIcon",
            variant: "danger",
          },
        });
      }
    },
    async checkForEmail() {
      const response = await this.emailCheck(this.userEmail);
      if (response.data.error?.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.data.error.message,
            icon: "BellIcon",
            variant: "danger",
          },
        });
      }
    },
    async checkForMobile() {
      const response = await this.mobileCheck(this.mobile);
      if (response.data.error?.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.data.error.message,
            icon: "BellIcon",
            variant: "danger",
          },
        });
      }
    },
    allAreNull() {
      return this.errors.every((element) => element === null);
    },
    goToLoginPage() {
      this.$router.push("/login");
    },
    async validationForm() {
      this.disabledRegisterButton = true
      const result = await this.$refs.vendorRegisterValidation.validate();
      if (result) {
        try {
          await this.vendorRegistration({
            identificationCode: this.identificationCode,
            email: this.userEmail,
            isIndividualEntrepreneur: this.isIndividualEntrepreneur,
            mobile: this.mobile,
            password: this.password,
          }).then((response) => {
            const parsedToken = parseJwt(response.data.data.token);
            localStorage.setItem("userInfo", JSON.stringify(parsedToken));
            Swal.fire({
              position: "center",
              icon: "success",
              text: this.$i18n.t("application.register-success"),
            });
            setTimeout(() => {
              this.$router.push("/");
              this.$router.go();
            }, 5000)
          });
          this.disabledRegisterButton = false
          // this.goToLoginPage();
          this.clearErrors();
        } catch (err) {
          console.log(err);
          this.disabledRegisterButton = false
        }
      }
    },
  },
};
// 599563658
</script>
<style scoped>
.privacyWrapper {
  display: flex;
  flex-direction: row;
}

.agree-text {
  margin-right: 4px;
}

.custom-card {
  max-width: 560px;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
